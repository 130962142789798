// PRODUCTION URL
export const PROD_URL = process.env.REACT_APP_PROD_BASE_URL;

// DEBUG URL
export const LOCAL_URL = process.env.REACT_APP_STAGING_BASE_URL;

// Constants
export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
export const FirebaseProdConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "tempo-native-app.firebaseapp.com",
  databaseURL: "https://tempo-native-app.firebaseio.com",
  projectId: "tempo-native-app",
  storageBucket: "tempo-native-app.appspot.com",
  messagingSenderId: "6168273235",
  appId: "1:6168273235:web:dce85c3a2b95922836c1a6",
  measurementId: "G-XYG01JPTSZ",
};

export const FirebaseStagingConfig = {
  apiKey: "AIzaSyDSTYlJ_biDSdpHytfIuwyFQ2htvt2R9B4",
  authDomain: "staging-tempo-live.firebaseapp.com",
  projectId: "staging-tempo-live",
  storageBucket: "staging-tempo-live.appspot.com",
  messagingSenderId: "798708247808",
  appId: "1:798708247808:web:2fb351c2c62fe52c7897e5",
  measurementId: "G-H15FZZEKYG",
};

export const STRIPE_KEYS = {
  staging: "pk_test_THy1fTovzXYBZFwchgsagzwU00HY9fzE5H",
  prod: process.env.REACT_APP_STRIPE_LIVE_KEY,
};

export const IS_STAGING = process.env["REACT_APP_FIREBASE_ENV"] === "staging";

export const FirebaseConfig = IS_STAGING
  ? FirebaseStagingConfig
  : FirebaseProdConfig;

export const URL = IS_STAGING ? LOCAL_URL : PROD_URL;

export const STRIPE_KEY = IS_STAGING ? STRIPE_KEYS.staging : STRIPE_KEYS.prod;

export const ABSTRACT_PHONE_VALIDATION_URL = "https://phonevalidation.abstractapi.com/v1";
export const SENDGRID_EMAIL_VALIDATION_URL = "https://api.sendgrid.com/v3/validations/email"

export const ABSTRACT_PHONE_API_KEY = "b7ecf55500fd4d4186661eaf9741657f";
export const SENDGRID_API_KEY = process.env.REACT_APP_SENDGRID_KEY